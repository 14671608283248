import { FC, SVGProps } from "react";

const LogoutOutlineIcon: FC<SVGProps<SVGSVGElement>> = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="logout">
            <path
                id="Icon (Stroke)"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.3609 12.6105C21.6792 12.2657 21.6792 11.7344 21.3609 11.3896L17.7609 7.4896C17.4238 7.12436 16.8544 7.10158 16.4892 7.43873C16.1239 7.77587 16.1011 8.34526 16.4383 8.7105L18.644 11.1L10.4996 11.1C10.0026 11.1 9.59961 11.503 9.59961 12C9.59961 12.4971 10.0026 12.9 10.4996 12.9L18.644 12.9L16.4383 15.2896C16.1011 15.6548 16.1239 16.2242 16.4892 16.5614C16.8544 16.8985 17.4238 16.8757 17.7609 16.5105L21.3609 12.6105ZM11.9996 21.9C11.9996 21.403 11.5967 21 11.0996 21L5.69961 21C4.87118 21 4.19961 20.3285 4.19961 19.5L4.19961 4.50005C4.19961 3.67162 4.87118 3.00005 5.69961 3.00005L11.0996 3.00005C11.5967 3.00005 11.9996 2.59711 11.9996 2.10005C11.9996 1.60299 11.5967 1.20005 11.0996 1.20005L5.69961 1.20005C3.87707 1.20005 2.39961 2.67751 2.39961 4.50005L2.39961 19.5C2.39961 21.3226 3.87707 22.8 5.69961 22.8L11.0996 22.8C11.5967 22.8 11.9996 22.3971 11.9996 21.9Z"
                fill="#7370FF"
            />
        </g>
    </svg>
);

export { LogoutOutlineIcon };
