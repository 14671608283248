import { FC, SVGProps } from "react";

const ArrowheadDownIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g id="arrowhead-down">
            <path
                id="Icon"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00078 10.3999C7.74898 10.3999 7.51186 10.2813 7.36078 10.0799L4.96079 6.87985C4.77898 6.63744 4.74973 6.31311 4.88524 6.04208C5.02076 5.77106 5.29777 5.59985 5.60078 5.59985H10.4008C10.7038 5.59985 10.9808 5.77106 11.1163 6.04208C11.2518 6.31311 11.2226 6.63744 11.0408 6.87985L8.64078 10.0799C8.4897 10.2813 8.25259 10.3999 8.00078 10.3999Z"
                fill="#C0C0CB"
            />
        </g>
    </svg>
);

export { ArrowheadDownIcon };
