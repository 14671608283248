import { FC, useState } from "react";
import { useClickAway } from "@/hooks/useClickAway";
import "./AccountPreview.scss";
import { ArrowheadDownIcon } from "@/components/icons/arrowhead-down";
import { SettingsOutlineIcon } from "@/components/icons/settings-outline";
import { MailOutlineIcon } from "@/components/icons/mail-outline";
import { LogoutOutlineIcon } from "@/components/icons/logout-outline";
import { useAuthStore } from "@/store/authStore";
import { keycloak } from "@/services/keycloak";
import { ACCESS_TOKEN_KEY, USER } from "@/constants/constants";
import { NavLink } from "react-router-dom";
import { ACCOUNT_SETTINGS } from "@/constants/routes";

const AccountPreview: FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const { setIsAuthentecated, setUser, setIsGlobalLoader, user } =
        useAuthStore();

    const ref = useClickAway<HTMLDivElement>(() => {
        setIsOpen(false);
    });

    const handleOpen = () => {
        setIsOpen(!isOpen);
    };

    const logout = (e: React.MouseEvent<HTMLElement>) => {
        try {
            e.preventDefault();
            setIsGlobalLoader(true);
            setTimeout(async () => {
                await keycloak.logout({
                    redirectUri: window.location.origin,
                });
                setIsAuthentecated(false);
                setUser(null);
                localStorage.removeItem(ACCESS_TOKEN_KEY);
                localStorage.removeItem(USER);
                setIsGlobalLoader(false);
            }, 1000);
        } catch {
            setIsGlobalLoader(false);
        }
    };

    return (
        <div className="account-preview" ref={ref}>
            <div
                className="account-preview--dropdown"
                onClick={handleOpen}
                id="account-preview--dropdown"
            >
                <span className="text">{`${user?.first_name} ${user?.last_name}`}</span>
                <ArrowheadDownIcon />
            </div>
            {isOpen && (
                <div
                    className="account-preview--links"
                    onClick={() => setIsOpen(false)}
                >
                    <NavLink to={ACCOUNT_SETTINGS} className="item-link">
                        <SettingsOutlineIcon />
                        Account Settings
                    </NavLink>
                    <div className="separator"></div>

                    <a
                        href="mailto:support_focal@mozn.sa"
                        rel="noreferrer"
                        target="_blank"
                        className="item-link"
                    >
                        <MailOutlineIcon />
                        Email us
                    </a>

                    <div className="separator"></div>

                    <div className="item-link" onClick={logout}>
                        <LogoutOutlineIcon />
                        Log out
                    </div>
                </div>
            )}
        </div>
    );
};

export { AccountPreview };
