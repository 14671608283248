import { SidebarStatusActionTypes } from "@moznsystems/mozn-ui";
import {
    createContext,
    FC,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";

type SidebarStatusContextType = {
    sidebarStatus: SidebarStatusActionTypes;
    handleSidebarStatus: (action: SidebarStatusActionTypes) => void;
};

const SidebarStatusContext = createContext<SidebarStatusContextType>({
    sidebarStatus: SidebarStatusActionTypes.OPEN_SIDEBAR,
    handleSidebarStatus: (_: SidebarStatusActionTypes) => {},
});

export const useSidebarStatusContext = (): SidebarStatusContextType =>
    useContext(SidebarStatusContext);

const SidebarStatusProvider: FC<{
    value?: SidebarStatusContextType;
    children?: React.ReactNode;
}> = (props) => {
    const [sidebarStatus, setSidebarStatus] =
        useState<SidebarStatusActionTypes>(
            SidebarStatusActionTypes.OPEN_SIDEBAR,
        );

    const memoizedIsSidebarOpen = useMemo(() => sidebarStatus, [sidebarStatus]);

    const handleSidebarStatus = (action: SidebarStatusActionTypes) => {
        switch (action) {
            case SidebarStatusActionTypes.OPEN_SIDEBAR:
                localStorage.setItem("sidebarStatus", JSON.stringify(action));
                return setSidebarStatus(SidebarStatusActionTypes.OPEN_SIDEBAR);
            case SidebarStatusActionTypes.CLOSE_SIDEBAR:
                localStorage.setItem("sidebarStatus", JSON.stringify(action));
                return setSidebarStatus(SidebarStatusActionTypes.CLOSE_SIDEBAR);
            case SidebarStatusActionTypes.TEMPORARILY_OPEN_SIDEBAR:
                localStorage.setItem("sidebarStatus", JSON.stringify(action));
                return setSidebarStatus(
                    SidebarStatusActionTypes.TEMPORARILY_OPEN_SIDEBAR,
                );
            default:
                return setSidebarStatus(SidebarStatusActionTypes.OPEN_SIDEBAR);
        }
    };

    useEffect(() => {
        const getSidebarStatus = localStorage.getItem("sidebarStatus");
        if (getSidebarStatus) {
            setSidebarStatus(JSON.parse(getSidebarStatus));
        }
    }, []);

    return (
        <SidebarStatusContext.Provider
            value={{
                sidebarStatus: memoizedIsSidebarOpen,
                handleSidebarStatus,
            }}
        >
            {props.children}
        </SidebarStatusContext.Provider>
    );
};

export default SidebarStatusProvider;
