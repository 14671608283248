import { FC, SVGProps } from "react";

const MailOutlineIcon: FC<SVGProps<SVGSVGElement>> = () => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="Mail">
            <path
                id="Icon (Stroke)"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.0763 6.42415L11.9992 11.5616L20.9221 6.42415C20.7232 5.82902 20.1613 5.4001 19.4992 5.4001H4.49922C3.83716 5.4001 3.27528 5.82902 3.0763 6.42415ZM20.9992 8.45679L12.4483 13.3801C12.1703 13.5401 11.8281 13.5401 11.5502 13.3801L2.99922 8.45679V17.1001C2.99922 17.9285 3.67079 18.6001 4.49922 18.6001H19.4992C20.3276 18.6001 20.9992 17.9285 20.9992 17.1001V8.45679ZM1.19922 6.9001C1.19922 5.07756 2.67668 3.6001 4.49922 3.6001H19.4992C21.3218 3.6001 22.7992 5.07756 22.7992 6.9001V17.1001C22.7992 18.9226 21.3218 20.4001 19.4992 20.4001H4.49922C2.67668 20.4001 1.19922 18.9226 1.19922 17.1001V6.9001Z"
                fill="#7370FF"
            />
        </g>
    </svg>
);

export { MailOutlineIcon };
