import {
    LayoutRoot,
    LayoutSideBar,
    LayoutSideBarFooter,
    LayoutSideBarHeader,
    LayoutTopBar,
    LogoGradient,
    SidebarStatusActionTypes,
} from "@moznsystems/mozn-ui";
import type { FC, PropsWithChildren } from "react";
import { useToast } from "@/components/toast";
import { addUnauthorizedInterceptor } from "@/services/api";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import logoHorizontal from "@/assets/images/logo_horizontal.svg";
import { SideBar } from "./SideBar/side-bar";
import { useSidebarStatusContext } from "@/store/sidebar-status-context";
import { AccountPreview } from "./AccountPreview";
import logoMini from "@/assets/images/logo_mini.svg";
import AppSwitcher from "./AppSwitcher";

const Layout: FC<PropsWithChildren> = ({ children }) => {
    const { sidebarStatus, handleSidebarStatus } = useSidebarStatusContext();
    const [isDrawerVisible, setDrawerVisible] = useState<boolean>();
    const toast: any = useToast();
    addUnauthorizedInterceptor(toast.pushError);

    return (
        <LayoutRoot
            isSideBarClose={
                sidebarStatus === SidebarStatusActionTypes.CLOSE_SIDEBAR
            }
        >
            <LayoutSideBar
                sidebarStatus={sidebarStatus}
                handleSidebarStatus={handleSidebarStatus}
                isDrawerVisible={isDrawerVisible}
                handleDrawerVisible={(value) => setDrawerVisible(value)}
            >
                <LayoutSideBarHeader>
                    <AppSwitcher />
                    <NavLink to="/" className="logo" aria-label="Focal">
                        <div className="logo__container logo__container--with-app-switcher">
                            <img
                                className="logo__container__horizontal"
                                src={logoHorizontal}
                                alt="Focal's logo"
                            />
                            <img
                                className="logo__container__mini"
                                src={logoMini}
                                alt="Focal's logo"
                            />
                            <p className="logo__container__text">
                                <span>Fraud</span>
                            </p>
                        </div>
                    </NavLink>
                </LayoutSideBarHeader>
                <SideBar
                    setDrawerVisible={(value) => setDrawerVisible(value)}
                />
                <LayoutSideBarFooter>
                    <div
                        className="nav-divider sticky bottom-0 left-0 h-[1px] w-[220px]"
                        data-testid="divider"
                    />
                    <div className="powered_by">
                        <span>By Mozn</span>
                        <LogoGradient />
                    </div>
                </LayoutSideBarFooter>
            </LayoutSideBar>
            <LayoutTopBar>
                <AccountPreview />
            </LayoutTopBar>
            {children}
        </LayoutRoot>
    );
};

export { Layout };
