import { Suspense, lazy, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ToastProvider } from "../components/toast/toast-provider";
import { Layout } from "../layout/Layout";
import { useAuthStore } from "../store/authStore";
import {
    EVENTS_ROUTE,
    DASHBOARD_ROUTE,
    NOT_FOUND_ROUTE,
    EVENT_DETAILS,
    CUSTOMERS_ROUTE,
    CUSTOMERS_DETAILS,
    DEVICES_ROUTE,
    DEVICES_DETAILS,
    SETTINGS_ROUTE,
    ACCOUNT_SETTINGS,
    LISTS_ROUTE,
    LISTS_DETAILS_ROUTE,
} from "../constants/routes";
import { Loader } from "@/components/loader/loader";
import SidebarStatusProvider from "@/store/sidebar-status-context";

const Events = lazy(() => import("./events/events"));
const EventDetails = lazy(() => import("./events/event-details"));
const Dashboard = lazy(() => import("./dashboard/dashboard"));
const Customers = lazy(() => import("./customers/customers"));
const CustomerDetails = lazy(() => import("./customers/customer-details"));
const Devices = lazy(() => import("./devices/devices"));
const DeviceDetails = lazy(() => import("./devices/device-details"));
const ListDetails = lazy(() => import("./lists/list-details"));
const Lists = lazy(() => import("./lists"));
const Settings = lazy(() => import("./settings/settings"));
const NotFoundPage = lazy(() => import("./not-found-page/not-found-page"));

export const Main = ({ authenticated, userData }: any) => {
    const { setIsAuthentecated, setUser } = useAuthStore();

    useEffect(() => {
        setIsAuthentecated(authenticated);
        setUser({
            id: userData.sub,
            email: userData.email,
            first_name: userData.given_name,
            last_name: userData.family_name,
            role: userData.resource_access?.["fraud"]?.roles?.[0] || [],
            email_verified: userData.email_verified,
            created_at: userData.created_timestamp,
            tenant: {
                id: userData.tenant_id,
                name: userData.tenant_name,
            },
        });
    }, []);

    return (
        <ToastProvider>
            <SidebarStatusProvider>
                <Layout>
                    <Suspense
                        fallback={
                            <div className="loader-container loader-container--center loader-container--full-height">
                                <Loader size="50" />
                            </div>
                        }
                    >
                        <Switch>
                            <Route exact path="/">
                                <Redirect to={DASHBOARD_ROUTE} />
                            </Route>
                            <Route
                                exact
                                path={EVENTS_ROUTE}
                                component={Events}
                            />
                            <Route
                                path={EVENT_DETAILS()}
                                component={EventDetails}
                            />
                            <Route
                                exact
                                path={DASHBOARD_ROUTE}
                                component={Dashboard}
                            />
                            <Route
                                exact
                                path={CUSTOMERS_ROUTE}
                                component={Customers}
                            />
                            <Route
                                path={CUSTOMERS_DETAILS()}
                                component={CustomerDetails}
                            />
                            <Route
                                exact
                                path={DEVICES_ROUTE}
                                component={Devices}
                            />
                            <Route
                                path={DEVICES_DETAILS()}
                                component={DeviceDetails}
                            />
                            <Route exact path={SETTINGS_ROUTE}>
                                <Redirect to={ACCOUNT_SETTINGS} />
                            </Route>

                            <Route exact path={LISTS_ROUTE} component={Lists} />

                            <Route
                                path={LISTS_DETAILS_ROUTE()}
                                component={ListDetails}
                            />

                            <Route path={SETTINGS_ROUTE} component={Settings} />
                            <Route
                                exact
                                path={NOT_FOUND_ROUTE}
                                component={NotFoundPage}
                            />

                            <Route path="*" component={NotFoundPage} />
                        </Switch>
                    </Suspense>
                </Layout>
            </SidebarStatusProvider>
        </ToastProvider>
    );
};

export default Main;
